import React from "react";
import Layout from "../components/Layout/Layout";
import Section from "../components/Section/Section";
import Footer from "../components/Footer/Footer";
import Seo from "../components/Seo/Seo";

const ArticleTemplate = props => {
  
  const name = props.pageContext.name;
  const sections = props.pageContext.sections;

  return (
    <Layout>
      <Seo title={"TestingWorks"} description={name} data={props.pageContext}/>
      <div id="content" className="d-flex flex-column justify-content-between" style={{minHeight: '90%'}}>
        <div className="d-flex flex-column">
        { 
          sections?.map((section, key) => (
            <Section key={key} type={section.__typename} section={section}></Section>
          ))
        }
        </div>
        <Footer />
      </div>
    </Layout>
  );
};

export default ArticleTemplate;